exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-businesses-terms-of-use-tsx": () => import("./../../../src/pages/businesses-terms-of-use.tsx" /* webpackChunkName: "component---src-pages-businesses-terms-of-use-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-ui-kit-tsx": () => import("./../../../src/pages/ui-kit.tsx" /* webpackChunkName: "component---src-pages-ui-kit-tsx" */),
  "component---src-pages-users-terms-of-use-tsx": () => import("./../../../src/pages/users-terms-of-use.tsx" /* webpackChunkName: "component---src-pages-users-terms-of-use-tsx" */)
}

